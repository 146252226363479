/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation
// eslint-disable-next-line
import {initializeApp} from "firebase/app";
// eslint-disable-next-line
import {getAnalytics} from "firebase/analytics";

// eslint-disable-next-line
const firebaseConfig = {
  apiKey: "AIzaSyAu0hFxz6qdb9LdPB-eZFCYmDNnU9saSWA",
  authDomain: "mitangs.tech",
  projectId: "portfolio-f6855",
  storageBucket: "portfolio-f6855.appspot.com",
  messagingSenderId: "316039613278",
  appId: "1:316039613278:web:809e3585488c86a4aa1dcf",
  measurementId: "G-M08EQM4C68"
};

// Initialize Firebase
// eslint-disable-next-line
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Mitang Hindocha",
  title: "Hi all, I'm Mitang",
  subTitle: emoji(
    "I am a 17 years old passionate Full Stack Developer 🚀 having an experience of building Web applications with JavaScript / Reactjs / Nodejs and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1IwQzaS2watklGLbldXyc2_neORSNo4fh/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  // twitter: "https://twitter.com/HindochaMitang",
  github: "https://github.com/Mitang321",
  linkedin: "https://www.linkedin.com/in/mitang-hindocha/",
  gmail: "contact@mitangs.tech",
  instagram: "https://www.instagram.com/mitang_24",
  fiverr: "https://www.fiverr.com/mitang_24",
  youtube: "https://www.youtube.com/@Mitang24",
  // upwork: "https://www.upwork.com/freelancers/~01243f9d56879caa54",
  discord: "https://discordapp.com/users/737924114748145665",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web applications"
    ),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },

    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "wordpress",
      fontAwesomeClassname: "fab fa-wordpress"
    },
    {
      skillName: "codepen",
      fontAwesomeClassname: "fab fa-codepen"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    // {
    //   skillName: "sql-database",
    //   fontAwesomeClassname: "fas fa-database"
    // },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    // {
    //   skillName: "java",
    //   fontAwesomeClassname: "fab fa-java"
    // },
    {
      skillName: "php",
      fontAwesomeClassname: "fab fa-php"
    },
    // {
    //   skillName: "swift",
    //   fontAwesomeClassname: "fab fa-swift"
    // },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },

    // {
    //   skillName: "docker",
    //   fontAwesomeClassname: "fab fa-docker"
    // },

    {
      skillName: "windows",
      fontAwesomeClassname: "fab fa-windows"
    },
    {
      skillName: "cloudflare",
      fontAwesomeClassname: "fab fa-cloudflare"
    },

    // {
    //   skillName: "linux",
    //   fontAwesomeClassname: "fab fa-linux"
    // },
    {
      skillName: "microsoft",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    // {
    //   skillName: "ubuntu",
    //   fontAwesomeClassname: "fab fa-ubuntu"
    // },
    {
      skillName: "github",
      fontAwesomeClassname: "fab fa-github"
    },

    {
      skillName: "digitalocean",
      fontAwesomeClassname: "fab fa-digital-ocean"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "40%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "FreeLancer",
      company: "Upwork & Fiverr",
      companylogo: require("./assets/images/upwork.jpg"),
      date: "May 2023 – Present",
      desc: "Self-motivated and ambitious individual pursuing a career as a self-employed freelancer on platforms like Fiverr and Upwork. Currently in the early stages of gaining experience and knowledge in the fields, with a strong commitment to continuous learning and professional development. Seeking opportunities to apply and expand upon my growing skills as a freelancer, while delivering high-qualit services and exceeding client expectations.",
      // descBullets: [
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      // ]
      footerLink: [
        {
          name: "Live Demo",
          url: "https://www.instagram.com/69memes_ai/"
        },
        {
          name: "Contact!",
          url: "mailto:contact@mitangs.tech"
        }
      ]
    }
    // {
    //   role: "Front-End Developer",
    //   company: "Quora",
    //   companylogo: require("./assets/images/quoraLogo.png"),
    //   date: "May 2017 – May 2018",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    // },
    // {
    //   role: "Software Engineer Intern",
    //   company: "Airbnb",
    //   companylogo: require("./assets/images/airbnbLogo.png"),
    //   date: "Jan 2015 – Sep 2015",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    // }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "HERE ARE A FEW OF THE PROGRAMMING PROJECTS I HAVE DEVELOPED.  ",
  projects: [
    {
      image: require("./assets/images/it.jpeg"),
      projectName: "ANYWAY IT SOLUTIONS",
      projectDesc:
        "ANYWAY IT SOLUTIONS is a leading distributor of a wide range of computer hardware products from various trusted brands.",

      footerLink: [
        {
          name: "Live Demo",
          url: "https://anywayit.web.app/"
        }

        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/ig-logo.png"),
      projectName: "Instagram Meme Bot",
      projectDesc:
        "Introducing the Python-based Instagram Meme Bot! This incredible bot utilizes Python to automatically generate hilarious memes and seamlessly uploads them as engaging reels on Instagram. If you're interested in personally testing the capabilities of this bot, please don't hesitate to contact me.",
      footerLink: [
        {
          name: "Live Demo",
          url: "https://www.instagram.com/69memes_ai/"
        },
        {
          name: "Contact!",
          url: "mailto:contact@mitangs.tech"
        }
        //  you can add extra buttons here.
      ]
    },

    {
      image: require("./assets/images/yt-bot.png"),
      projectName: "Youtube Meme Bot",
      projectDesc:
        "Introducing the Python-based YouTube Meme Bot! This powerful bot leverages Python to automatically generate captivating memes and effortlessly uploads them as short videos on YouTube. If you're interested in personally testing the capabilities of this bot, please don't hesitate to contact me.",
      footerLink: [
        {
          name: "Live Demo",
          url: "https://www.youtube.com/channel/UC4sC53rGFiA9Tz-RRDqumuQ"
        },
        {
          name: "Contact!",
          url: "mailto:contact@mitangs.tech"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

const bigProjects1 = {
  title: "Products",
  subtitle: "HERE ARE A FEW OF THE PROGRAMMING Products I HAVE DEVELOPED.  ",
  projects: [
    {
      image: require("./assets/images/Whatsapp.webp"),
      projectName: "Whatsapp Price Bot",
      projectDesc:
        "The WhatsApp Bot is an innovative solution engineered for real-time customer engagement. It comes equipped with interactive messaging capabilities that not only inform but also engage your audience. A unique whitelist option adds an extra layer of exclusivity, allowing only selected numbers to receive messages. What sets this bot apart is its ability to fetch real-time data directly from a Google Spreadsheet. This ensures that your customers are always in the loop with the most current and relevant information. It's more than just a bot; it's a comprehensive tool for enhancing customer interaction and satisfaction.",
      footerLink: [
        {
          name: "View Product Details",
          url: "https://products.mitangs.tech/#/blog/WHATSAPP%20PRICE%20LIST%20BOT/1"
        }

        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};
// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Web Programming with Python and Javascript",
      subtitle: "Awarded From HARVARD UNIVERSITY, Cambridge, Massachusetts.",
      image: require("./assets/images/harvardLogo.png"),
      imageAlt: "Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://certificates.cs50.io/f7ef2882-533a-49bf-9440-272de688fd88.png?size=A4"
        }
        // {
        //   name: "View Projects",
        //   url: "https://github.com/Mitang321/CS50W-All-PROJECTS"
        // }
      ]
    },
    {
      title: "Introduction to Programming with Python.",
      subtitle: "Awarded From HARVARD UNIVERSITY, Cambridge, Massachusetts.",
      image: require("./assets/images/harvardLogo.png"),
      imageAlt: "Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://certificates.cs50.io/2dbc3820-5db3-4be4-8d7b-9326b5ab38c4.png"
        }
        // {
        //   name: "View Final Project",
        //   url: "https://github.com/Mitang321/CS50P-All-PROBLEM-SETS/tree/main/Final_Project/TO_DO_MANAGER_Final_Project"
        // }
      ]
    },

    {
      title: "Google Analytics Individual Qualification ",
      subtitle: "Google Analytics by United Latino Students Association",
      image: require("./assets/images/Analytisc.jpeg"),
      imageAlt: "Google Analytics Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://skillshop.exceedlms.com/student/award/jCjapuQWLeo6sJ76FanUUEhV"
        }
      ]
    },
    {
      title: "Fundamentals Of Digital Marketing",
      subtitle: "Google Digital Garage",
      image: require("./assets/images/google_digital.png"),
      imageAlt: "Google Digital",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://skillshop.exceedlms.com/student/award/nueMwVwF9i21rbS32RdzJVuW"
        }
      ]
    },
    {
      title: "Badges Earned From GOOGLE CLOUD",
      subtitle: "Google Cloud Skills Boost",
      image: require("./assets/images/gcp.jpg"),
      imageAlt: "gcp Logo",
      footerLink: [
        {
          name: "View Badges",
          url: "https://www.cloudskillsboost.google/public_profiles/b30f4d68-d915-46aa-94d2-69583f74803b"
        }
      ]
    },
    {
      title: "Career Essentials in Generative AI by Microsoft and LinkedIn",
      subtitle: "LinkedIn Learning",
      image: require("./assets/images/ai-linkdin1.png"),
      imageAlt: "Google Analytics Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://www.linkedin.com/learning/certificates/de644c5babd1a315aba0ff5db30d1712907d659924a51433e6cc453fcaa2f591"
        }
      ]
    },

    {
      title: "TROPHIES And BADGES Earned From MICROSOFT",
      subtitle: "MICROSOFT LEARNING",
      image: require("./assets/images/MicrosoftLearning.png"),
      imageAlt: "Udemy Logo",
      footerLink: [
        {
          name: "View TROPHIES AND BADGES",
          url: "https://learn.microsoft.com/en-us/users/mitangs/"
        }
      ]
    },

    {
      title: "Web Design & Development By Skill India",
      subtitle: "National Skill Development Corporation (NSDC)",
      image: require("./assets/images/nsdc.jpg"),
      imageAlt: "NSDC Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1q61BcvY1wCxnS_saqSb-gu2Puf2oK2rI/view?usp=sharing"
        }
      ]
    },

    {
      title: "Cyber Security and Digital Forensics",
      subtitle: "e-learning and Data Analytics lab By IIIT KOTA ",
      image: require("./assets/images/kota_logo.png"),
      imageAlt: "NSDC Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://eldalab.in/tutor-certificate?cert_hash=b4c32df1c9a7256c"
        }
      ]
    },

    {
      title: "Basics of Python By Infosys",
      subtitle: "Infosys | Springboard",
      image: require("./assets/images/infosys.png"),
      imageAlt: "Infosys Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1yzHGWtLLyIUzDj640zBlP3jHVDRLgu-N/view?usp=sharing"
        }
      ]
    },

    {
      title: "Certificates From Udemy",
      subtitle:
        "These are all the certificates I received by completing various courses from Udemy.",
      image: require("./assets/images/udemy.png"),
      imageAlt: "Udemy Logo",
      footerLink: [
        {
          name: "View Certificates",
          url: "https://drive.google.com/drive/folders/1ytKV6mXNJuZaHXcUb5UxkXGgfr8mzXeO?usp=sharing"
        }
      ]
    }
  ],
  display: true
  // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.mitangs.tech/",
      title: "Read All The Blogs",
      description: "All of the latest Blogs are available here"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",

  email_address: "contact@mitangs.tech",
  display: true
};

// Twitter Section

const twitterDetails = {
  userName: "hindochamitang", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  bigProjects1,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
